/* Modal */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.modalContainer {
    max-width: 600px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
}

img {
    width: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.modalRight {
    width: 100%;
}

.closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
}

.btnContainer {
    display: flex;
    padding: 1rem 1rem;
    justify-content: center;
}
.btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 0px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    height: 40px;
}

.btnPrimary {
    background-color: #411b57;
    color: white;
}

.btnPrimary:hover {
    cursor: pointer;
}

.btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
}

.bold {
    font-weight: 600;
}

@media screen and (max-width: 500px) {
    .modalContainer {
        flex-direction: column;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100vh;
    }
    img {
        width: 100%;
        max-height: 70vh;
        object-fit: cover;
    }

    .heading {
        margin: 1rem;
    }
}

input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.modalRight {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 70%;
    margin: 30px auto;
}

#errorText {
    display: none;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
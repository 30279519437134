.mainSection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    /*top: 80px;*/
}

.sections {
    width: 80%;
    margin-top: 60px;
}

.thumbnail {
    width: 430px;
    height: 430px;
}

.thumbnail:hover, .thumbnailSpecial:hover, .thumbnailContain:hover {
    cursor: pointer;
}

.thumbnailSpecial {
    width: 430px;
    height: 430px;
    object-fit: cover;
}

.thumbnailContain {
    width: 430px;
    height: 430px;
    object-fit: contain;
}

.items {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}

.noProduct {
    margin-top: 50px;
}

.searchResultsHeader {
    margin-top: 50px;
}

li {
    margin-right: 30px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #0062b2;
}

.banner {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    height: 500px;
}

.bannerBackground {
    width: 100%;
    height: 500px;
}

.more {
    margin-bottom: 40px;
}

.messageLayer {
    z-index: 99999999;
    position: fixed;
    right: 10px;
    bottom: 10px;
}

#floatShow {
    height: 50px;
    width: 150px;
    float: right;
    position: relative;
    -webkit-box-shadow: 0 5px 20px 0 rgba(58, 154, 193, .1);
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    padding: 5px 10px 5px 20px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    cursor: pointer;
    -webkit-transition: all 1s cubic-bezier(.86, 0, .07, 1);
    transition: all 1s cubic-bezier(.86, 0, .07, 1);
}

#floatShow p {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    display: inline-block;
    line-height: 18px;
    position: absolute;
    right: 30px;
    top: 25px;
    margin-top: -10px;
    opacity: 1;
    transition: all .8s;
    height: 40px;
    max-width: 180px;
    font-family: titilliumweb semibold;
}


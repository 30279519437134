.introAndInterestedButton{
    width: 70%;
    margin: auto;
    display: flex;
}

.mainPart {
    margin-top: 100px;
}

.image-gallery-slide img {
    width: 100%;
    height: 900px;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.briefIntro {
    margin-top: 0;
    margin-left: 10px;
    flex: 1;
}

.blockButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.imageSec {
    width: 70%;
    margin: 100px auto;
    height: auto;
}

.topLi {
    padding-top: 0 !important;
}

.briefIntroLi {
    font-size: 20px;
    padding-top: 40px;
}

.features {
    margin-top: 50px;
}

/*.block {*/
/*    display: inline-block;*/
/*    margin-right: 6px;*/
/*    vertical-align: top;*/
/*}*/

.imageDiv {
    margin-bottom: 20px;
}

.productTitle {
    font-size: 25px;
    text-align: center;
    margin-bottom: 100px;
}

.detailDiv {
    width: 70%;
    margin: 50px auto;
    height: auto;
}

.detailsDictUl {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.detailsDictLi {
    font-size: 15px;
    justify-content: space-around;
    padding-top: 3px;
}

.packageInfoDiv {
    margin-top: 10px;
}

.interestedButton {
    margin: 0;
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 0px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    height: 40px;
}

.infoLink:before
{
    content: '?';
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 1.8ex;
    height: 1.8ex;
    font-size: 1.4ex;
    line-height: 1.8ex;
    border-radius: 1.2ex;
    margin-right: 4px;
    padding: 1px;
    color: blue;
    background: white;
    border: 1px solid blue;
    text-decoration: none;
}

.infoLink:hover:before
{
    color: white;
    background: blue;
    border-color: white;
    text-decoration: none;
}

.infoLink .tooltipText {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.infoLink .tooltipText:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

.infoLink:hover .tooltipText {
    visibility: visible;
}

@media screen and (max-width: 800px) {
    .introAndInterestedButton{
        width: 85%;
        margin: auto;
        display: flex;
    }

    .interestedButton {
        background: #5E5DF0;
        border-radius: 999px;
        box-shadow: #5E5DF0 0 10px 20px -10px;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 0px;
        opacity: 1;
        outline: 0 solid transparent;
        padding: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        word-break: break-word;
        border: 0;
        height: 40px;
        width: 100%;
    }
}